import { AlertColor } from "@mui/material";
import { map } from "nanostores";

export interface SnackbarMessage {
	message: string;
	autoHide: boolean;
	loading?: boolean;
	buttonText?: string;
	mode?: "light" | "dark";
	loadingMsg?: string;
	severity?: AlertColor;
}

// TODO: make snackStore for multiple messages
// export const msgs = atom<SnackbarMessage[]>([]);
export const msg = map<SnackbarMessage>(null);
