import { useState } from "react";

import { Button, Divider, SelectChangeEvent, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useStore } from "@nanostores/react";
import FormSelect from "common/components/FormSelect/FormSelect";
import { user } from "common/stores/UserStore";
import { logoutRedirect } from "modules/auth/AuthUtils";
import { IMenuItem } from "./FormSelect/FormSelect";

import AdminClient from "modules/facade/AdminClient";
import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";
import BinaryModal from "./BinaryModal";

interface AvatarMenuProps {
	setAnchorEl: Function;
	organization: string;
	anchorEl: null | HTMLElement;
	onOrganizationChange?: () => void;
}

type ModalEnum = "" | "changeOrg";

export default function AvatarMenu({
	setAnchorEl,
	organization,
	anchorEl,
	onOrganizationChange,
}: AvatarMenuProps) {
	const $user = useStore(user);
	const router = useRouter();

	const [activeModal, setActiveModal] = useState<ModalEnum>("");
	const [changeOrgModal, setChangeOrgModal] = useState(null);

	let currentOrgId: string = organization ?? "Loading";
	let orgs: IMenuItem[] = [{ text: "Loading...", value: "" }];

	const getLandingPageForNewOrg = (orgId: string, currentPath: string) => {
		const MONARCH_URL = process.env.MONARCH_URL;
		const BASE_FE_URL = process.env.BASE_FE_URL;
		const ESG_INSIGHTS_HOME = "/esgInsights/ng";
		let newPath;
		if (currentPath.includes(ESG_INSIGHTS_HOME)) {
			if (
				currentPath.includes("/esgInsights/ng/inventory-data/activities?") ||
				currentPath.includes("/esgInsights/ng/scope") ||
				currentPath.includes("/esgInsights/ng/renewables")
			) {
				// these should be generic, non-deep-dive pages
				newPath = currentPath;
			} else if (currentPath.includes("/esgInsights/ng/renewable/")) {
				// renewables has a generic list page
				newPath = "/esgInsights/ng/renewables";
			} else if (
				currentPath.includes("/esgInsights/ng/inventory-data/activities") ||
				currentPath.includes(
					"/esgInsights/ng/inventory-data/inventory-data/activity/"
				) ||
				currentPath.includes("/esgInsights/ng/inventory-data/facility/")
			) {
				// facilities and activities pages all redirect to the activities list
				newPath = "/esgInsights/ng/inventory-data/activities";
			} else {
				newPath = ESG_INSIGHTS_HOME;
			}
		}
		newPath ||= "/";
		const url = `${MONARCH_URL}/auth/my-organization/${orgId}?redirect_success=${encodeURIComponent(
			`${BASE_FE_URL}${newPath}`
		)}&redirect_fail=${encodeURIComponent(`${BASE_FE_URL}${newPath}`)}`;
		return url;
	};

	const isAdmin = $user.roles.includes("write:organization");
	const apiKeyFeatureAccess = $user.feature_flags.includes("api-keys");
	const isMultiOrgAdmin = $user.roles.includes("multiorg:admin");

	let currentOrgName: string =
		isAdmin || isMultiOrgAdmin ? "Loading" : $user.organization;

	const formatOrgs = (respOrgs) => {
		if (respOrgs?.length) {
			const uniqueOrgs = [
				...new Map(
					respOrgs.map((item) => {
						return [item.name, { text: item.name, value: item.id }];
					})
				).values(),
			] as IMenuItem[];
			const sortedOrgs = uniqueOrgs.sort((a, b) =>
				a.text.localeCompare(b.text)
			) as IMenuItem[];
			const selectedOrg = uniqueOrgs.filter(
				(org) => org.value == $user.organization_id
			)[0];
			currentOrgId = selectedOrg?.value;
			currentOrgName = selectedOrg?.text;
			orgs = sortedOrgs;
		}
	};

	// org list is only relevant if role is optera admin || multiorg admin
	const { data, error } = useSWR(
		isAdmin || isMultiOrgAdmin ? "/auth/my-organizations" : null,
		() => AdminClient.getMyOrganizations(),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
		}
	);

	if (data ?? data?.length) {
		formatOrgs(data);
	}

	if (error) {
		console.error(error);
	}

	const handleOrgChange = (event: SelectChangeEvent) => {
		const prevOrg = currentOrgName;
		const selectedOrg = orgs.filter(
			(org) => org.value == event.target.value
		)[0];
		currentOrgId = selectedOrg?.value;
		currentOrgName = selectedOrg?.text;

		setChangeOrgModal({
			headerText: "Are you sure you want to login to a different organization?",
			bodyText: `You have indicated you want to logout of ${prevOrg} and login to ${selectedOrg?.text}.`,
			primaryButtonText: "Yes",
			primaryButtonAction: () => {
				setAnchorEl(null);
				router.replace(
					getLandingPageForNewOrg(selectedOrg?.value, router.pathname)
				);
				currentOrgId = selectedOrg?.value;
				if (onOrganizationChange) {
					onOrganizationChange();
				}
			},
			secondaryButtonText: "Cancel",
			secondaryButtonAction: () => {
				setActiveModal("");
			},
		});
		setActiveModal("changeOrg");
	};

	return (
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
			id="account-menu"
			slotProps={{
				paper: {
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						p: 3,
						width: "450px",
						maxHeight: "None",
					},
				},
			}}
			transformOrigin={{ horizontal: "right", vertical: "top" }}
			anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
		>
			<MenuItem key="avatarMenuItem-orgLabel">
				<Stack pb={2}>
					<p className="subtitle2 pb-2">My Organization</p>
					{isAdmin || isMultiOrgAdmin ? (
						<FormSelect
							value={currentOrgId}
							onChange={handleOrgChange}
							menuItemList={orgs}
							sx={{
								width: "100%",
								maxWidth: "480px",
							}}
							noBlankOption
						/>
					) : (
						<p className="body1">{currentOrgName}</p>
					)}
				</Stack>
			</MenuItem>
			{isAdmin && (
				<div>
					<Link href="/admin/organizations" key="avatarMenuItem-userManagement">
						<MenuItem>
							<Stack pb={2}>
								<p className="body1">Organization Management</p>
								<span className="caption text-secondary">
									Create, Edit, and Delete Organizations
								</span>
							</Stack>
						</MenuItem>
					</Link>
					<Link href="/admin/logs/orgLogs" key="avatarMenuItem-logs">
						<MenuItem>
							<Stack pb={2}>
								<p className="body1">Logs</p>
								<span className="caption text-secondary">
									View user and org logs
								</span>
							</Stack>
						</MenuItem>
					</Link>
					{apiKeyFeatureAccess && (
						<Link href="/admin/api-keys" key="avatarMenuItem-api-keys">
							<MenuItem>
								<Stack pb={2}>
									<p className="body1">API Key Management</p>
									<span className="caption text-secondary">
										Manage API keys
									</span>
								</Stack>
							</MenuItem>
						</Link>
					)}
				</div>
			)}
			<Divider />
			<Button
				sx={{ mt: 2 }}
				color="secondary"
				variant="outlined"
				onClick={() => {
					logoutRedirect("logout", "/", "/");
				}}
			>
				Logout
			</Button>
			<BinaryModal
				openModal={activeModal === "changeOrg"}
				{...changeOrgModal}
			/>
		</Menu>
	);
}
