import React from "react";

import { Box, Button, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

export interface IBinaryModalProps {
	headerText?: string;
	bodyText?: string;
	primaryButtonText: string;
	primaryButtonIcon?: React.ReactNode;
	secondaryButtonText?: string;
	openModal: boolean;
	primaryButtonAction: () => void;
	secondaryButtonAction?: () => void;
	primaryButtonColor?:
		| "inherit"
		| "secondary"
		| "primary"
		| "success"
		| "error"
		| "info"
		| "warning"
		| "delete";
	secondaryButtonColor?:
		| "inherit"
		| "secondary"
		| "primary"
		| "success"
		| "error"
		| "info"
		| "warning"
		| "delete";
	primaryButtonVariant?: "text" | "outlined" | "contained";
	secondaryButtonVariant?: "text" | "outlined" | "contained";
	children?: any;
	primaryDisabled?: boolean;
	secondaryDisabled?: boolean;
	modalSkipButtonAction?: () => void;
	skipDirection?: string;
}

export const BINARY_MODAL_CLOSED_DEFAULT: IBinaryModalProps = {
	openModal: false,
	headerText: "DEFAULT_MODAL_HEADER",
	bodyText: "DEFAULT_MODAL_BODY",
	primaryButtonText: "PRIMARY",
	secondaryButtonText: "SECONDARY",
	primaryButtonAction: () => {},
	secondaryButtonAction: () => {},
};

export default function BinaryModal({
	primaryButtonText,
	primaryButtonIcon,
	secondaryButtonText,
	headerText,
	bodyText,
	openModal,
	primaryButtonAction,
	secondaryButtonAction,
	primaryButtonColor = "secondary",
	secondaryButtonColor = "secondary",
	primaryButtonVariant = "contained",
	secondaryButtonVariant = "outlined",
	children,
	primaryDisabled,
	secondaryDisabled,
	modalSkipButtonAction,
	skipDirection,
}: IBinaryModalProps) {
	const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.code === "Enter") {
			primaryButtonAction();
		}
	};
	return (
		<Dialog open={openModal} fullWidth maxWidth="md" onKeyUp={keyUpHandler}>
			<Stack spacing={4} sx={{ p: 4 }}>
				<Stack spacing={4} sx={{ pl: 3 }}>
					<h4>{headerText}</h4>
					<p className="body1">{bodyText}</p>
					{children}
				</Stack>
				<DialogActions sx={{ pl: 3 }}>
					<Button
						variant={primaryButtonVariant}
						onClick={primaryButtonAction}
						size="medium"
						color={primaryButtonColor}
						disabled={primaryDisabled}
						endIcon={primaryButtonIcon}
					>
						{primaryButtonText}
					</Button>
					{secondaryButtonText && secondaryButtonAction && (
						<Button
							variant={secondaryButtonVariant}
							onClick={secondaryButtonAction}
							size="medium"
							color={secondaryButtonColor}
							disabled={secondaryDisabled}
						>
							{secondaryButtonText}
						</Button>
					)}
					{modalSkipButtonAction && (
						<Button
							variant={secondaryButtonVariant}
							onClick={modalSkipButtonAction}
							size="medium"
							color={secondaryButtonColor}
							disabled={secondaryDisabled}
						>
							{skipDirection === "forward"
								? "Save & continue to next section"
								: "Save & continue to previous section"}
						</Button>
					)}
					<Box sx={{ flexGrow: 1 }} />
				</DialogActions>
			</Stack>
		</Dialog>
	);
}
