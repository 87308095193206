import { Icon, InputAdornment } from "@mui/material";
import React from "react";

export interface Adornment {
	position: "start" | "end";
	content: string | React.ReactElement<typeof Icon>;
}

export interface StartAdornment extends Adornment {
	position: "start";
}

interface AdornmentInputProp {
	startAdornment?: React.ReactElement<typeof InputAdornment>;
	endAdornment?: React.ReactElement<typeof InputAdornment>;
}

export function getAdornmentForInputProps(
	adornment: Adornment,
	disabled?: boolean
): AdornmentInputProp {
	return {
		[`${adornment.position}Adornment`]: (
			<InputAdornment
				position={adornment.position}
				sx={{
					color: disabled
						? "var(--oc-palette-text-disabled)"
						: "var(--oc-palette-secondary-700)",
					"& .MuiTypography-root": {
						color: disabled
							? "var(--oc-palette-text-disabled)"
							: "var(--oc-palette-secondary-700)",
					},
				}}
			>
				{adornment.content}
			</InputAdornment>
		),
	};
}
