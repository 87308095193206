import { IUser } from "common/utils/UserUtils";
import { KyResponse } from "ky";
import MonarchClient from "./MonarchClient";

export interface CreateAPIKeyResponse {
	api_key: string;
	key_name: string;
}

export interface APIKeyStatusResponse {
	message: string;
}
export interface APIKeyResponse {
	name: string;
	owner_username: string;
	created_at: Date;
	active: boolean;
}

interface APIKeyOwnershipTransferRequest {
	api_key_name: string;
	new_owner_username: string;
}
interface CreateAPIKeyRequest {
	owner: string;
}

class AuthClient {
	static async whoami(): Promise<IUser> {
		return MonarchClient.get("auth/whoami").then((res) => res.json());
	}

	static async fetchApiKeys(): Promise<APIKeyResponse[]> {
		return MonarchClient.get("auth/api-keys").then((res) => res.json());
	}

	static async createApiKey(request: CreateAPIKeyRequest): Promise<KyResponse> {
		return MonarchClient.post("auth/api-key", {
			json: request,
		});
	}

	static async deactivateApiKey(keyName: string): Promise<KyResponse> {
		return MonarchClient.put(`auth/api-key/deactivate/${keyName}`);
	}

	static async reactivateApiKey(keyName: string): Promise<KyResponse> {
		return MonarchClient.put(`auth/api-key/reactivate/${keyName}`);
	}

	static async deleteApiKey(keyName: string): Promise<KyResponse> {
		return MonarchClient.delete(`auth/api-key/${keyName}`);
	}

	static async transferApiKeyOwnership(
		transferRequest: APIKeyOwnershipTransferRequest
	): Promise<KyResponse> {
		return MonarchClient.post("auth/api-key/transfer-ownership", {
			json: transferRequest,
		});
	}
}

export default AuthClient;
