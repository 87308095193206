import { Box, Stack, LinearProgress } from "@mui/material";
import Image from "next/legacy/image";
import logo from "public/images/OPT-Primary-Black-min.svg";

const FullPageSpinner = ({ message }: { message?: string }) => (
	<Stack
		spacing={4}
		direction="column"
		alignItems="center"
		justifyContent="center"
		style={{ minHeight: "100vh" }}
	>
		<Image
			src={logo}
			alt="Optera Logo"
			width={177}
			height={48}
			layout="fixed"
		/>
		<Box sx={{ width: "60%" }}>
			<LinearProgress />
		</Box>
		{message ? <p>{message}</p> : null}
	</Stack>
);

export default FullPageSpinner;
