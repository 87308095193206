import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
	Alert,
	AlertColor,
	Button,
	CircularProgress,
	IconButton,
	Slide,
	Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStore } from "@nanostores/react";
import { msg } from "common/stores/SnackbarStore";
import React, { useCallback, useEffect, useState } from "react";

const TransitionUp = (props) => <Slide {...props} direction="up" />;

function SnackbarReaderComponent() {
	const theme = useTheme();
	const $msg = useStore(msg);

	const [open, setOpen] = useState(!!$msg);
	useEffect(() => {
		if ($msg) {
			setOpen(true);
		}
	}, [$msg]);

	const message = $msg?.message || "";
	const severity: AlertColor = $msg?.severity || "success";
	const loading = $msg?.loading || false;
	const mode = $msg?.mode || "dark";
	const loadingMsg = $msg?.loadingMsg || "Loading...";
	const buttonText = $msg?.buttonText;

	const iconToBeUsed = () => {
		if (severity === "error") {
			return (
				<ErrorOutlineIcon
					sx={{
						color: `var(--oc-palette-${severity}-main)`,
					}}
				/>
			);
		}
		if (severity === "info") {
			return (
				<ErrorOutlineIcon
					sx={{
						color: `var(--oc-palette-${severity}-main)`,
						rotate: "180deg",
					}}
				/>
			);
		}
		if (severity === "warning") {
			return (
				<WarningAmberIcon
					sx={{
						color: `var(--oc-palette-${severity}-main)`,
					}}
				/>
			);
		}
		if (severity === "success") {
			return (
				<CheckIcon
					sx={{
						color: `var(--oc-palette-${severity}-main)`,
					}}
				/>
			);
		}
	};

	const handleClose = useCallback(
		(_: any, reason?: string) => {
			if (reason === "clickaway") {
				return;
			}
			setOpen(false);
			setTimeout(() => {
				msg.set(null);
			}, 500); // Added so the content doesn't disappear before the snackbar leaves viewport
		},
		[setOpen]
	);

	const action = (
		<>
			{buttonText ? (
				<Button
					color="secondary"
					variant="text"
					sx={{
						fontSize: "14px",
						fontWeight: 700,
						color:
							mode == "light"
								? theme.palette.text.primary
								: theme.palette.secondary[50],
						"&:hover": {
							backgroundColor:
								mode == "light"
									? theme.palette.secondary.light
									: theme.palette.secondary[700],
						},
					}}
					size="small"
					onClick={handleClose}
				>
					{buttonText}
				</Button>
			) : null}

			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
				sx={{
					"&:hover": {
						backgroundColor:
							mode == "light"
								? theme.palette.secondary.light
								: theme.palette.secondary[700],
					},
				}}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	const calcDuration = () => {
		if (message !== "") {
			const wordsArray = message.split(" ");
			if (wordsArray.length < 5) {
				return 7000;
			}
			if (wordsArray.length < 8) {
				return 10000;
			}
			if (wordsArray.length > 8) {
				return 14000;
			}
		}
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			autoHideDuration={calcDuration()}
			onClose={handleClose}
			open={open}
			TransitionComponent={TransitionUp}
		>
			<Alert
				icon={
					loading ? (
						<CircularProgress
							size={20}
							sx={{
								color:
									mode == "light" ? "var(--oc-palette-text-primary)" : "#fff",
							}}
						/>
					) : (
						iconToBeUsed()
					)
				}
				variant="standard"
				action={action}
				elevation={6}
				sx={{
					alignItems: "center !important",
					backgroundColor: mode == "light" ? "#fff" : "#000",
					color: mode == "light" ? theme.palette.text.primary : "#fff",
				}}
			>
				{loading ? loadingMsg || "Loading..." : message}
			</Alert>
		</Snackbar>
	);
}

const SnackbarReader = React.memo(SnackbarReaderComponent); // do we still need this memo thing?
export default SnackbarReader;
